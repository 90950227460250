/**
 * OpsDimensioners REST API
 * OpsDimensioners API from XPO
 *
 * The version of the OpenAPI document: 1.0.0-1-3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Inspection Dimensioner Log.
 */
export interface InsDimensionerLogDto { 
    dimensionerLogId?: string;
    shpInstId?: number;
    /**
     * Must be a valid pro number.
     */
    proNbr?: string;
    pkupDt?: string;
    sicCode?: string;
    dimensionerId?: string;
    dimTmst?: Date;
    lengthNbr?: number;
    widthNbr?: number;
    heightNbr?: number;
    statusCd?: string;
    crteBy?: string;
    crteTmst?: Date;
    crtePgmId?: string;
    lstUpdtBy?: string;
    lstUpdtTmst?: Date;
    lstUpdtPgmId?: string;
    correlationId?: string;
    recordVersionNbr?: number;
    insEventId?: number;
    measurementId?: number;
    forkliftNbr?: number;
    weightLbs?: number;
    originalVolCft?: number;
    correctedVolCft?: number;
    originalDensityPsf?: number;
    correctedDensityPsf?: number;
    adjustedDensityPsf?: number;
    originalNmfcClass?: string;
    correctedNmfcClass?: string;
    originalNmfcItemNbrTxt?: string;
    correctedNmfcItemNbrTxt?: string;
    exceptionCd?: string;
    exceptionDesc?: string;
    revenueOriginalAmt?: number;
    revenuePreviewAmt?: number;
    revenueCorrectedAmt?: number;
    corrReqInstId?: number;
    forkliftOperatorId?: string;
    adjustedLengthNbr?: number;
    adjustedWidthNbr?: number;
    adjustedHeightNbr?: number;
    /**
     * Stored image 1 to DMS timestamp
     */
    storedImg1DmsTmst?: number;
    /**
     * Stored image 2 to DMS timestamp
     */
    storedImg2DmsTmst?: number;
    /**
     * Stored image 1 to DMS timestamp
     */
    storedImg3DmsTmst?: number;
}

