import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesNoOption' })
export class YesNoOptionPipe implements PipeTransform {
  static readonly DEFAULT_NULL_VALUE: string = 'N/A';

  static format(option: boolean): string {
    if (typeof option === 'boolean') {
      return option ? 'Yes' : 'No';
    }

    return '';
  }

  static transformStatic(option: boolean): string {
    return YesNoOptionPipe.format(option);
  }

  transform(option: boolean): string {
    return YesNoOptionPipe.format(option);
  }
}
