/**
 * OpsDimensioners REST API
 * OpsDimensioners API from XPO
 *
 * The version of the OpenAPI document: 1.0.0-1-3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The model of ... request body.
 */
export interface CubiscanDimensionerCsMeasureRequestBodyModel { 
    /**
     * just for the needs of the service, this field doesnt exist
     */
    cubiscanSerial?: string;
    barcode1?: string;
}

