/**
 * OpsDimensioners REST API
 * OpsDimensioners API from XPO
 *
 * The version of the OpenAPI document: 1.0.0-1-3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Cubiscan response sample for testing.
 */
export interface CubiscanDeviceScanningResponseMeasurementDto {
  /**
   * Provided example:0
   */
  status?: number;
  /**
   * Provided example:\'success\' or measure error text
   */
  message?: string;
  /**
   * Provided example:null
   */
  barcode1?: string;
  /**
   * Provided example:null
   */
  barcode2?: string;
  /**
   * Provided example:\'2024-03-06 13:09:52\'
   */
  date_time?: string;
  /**
   * Provided example:\'2024-04-26T151023.728Z\'
   */
  date_time_iso?: string;
  /**
   * Provided example:48.0
   */
  net_length?: number;
  /**
   * Provided example:40.0
   */
  net_width?: number;
  /**
   * Provided example:55.0
   */
  net_height?: number;
  /**
   * Provided example:\'in\'
   */
  dimensional_unit?: string;
  /**
   * Provided example:345.0
   */
  net_weight?: number;
  /**
   * Provided example:\'lb\'
   */
  weight_unit?: string;
  /**
   * Provided example:61.111
   */
  net_volume?: number;
  /**
   * Provided example:61.111
   */
  net_real_volume?: number;
  /**
   * Provided example:\'ft\'
   */
  volume_unit?: string;
  /**
   * Provided example:636.1
   */
  net_dim_weight?: number;
  /**
   * Provided example:166
   */
  dim_weight_factor?: number;
  site_id?: string;
  cubiscan_id?: string;
  /**
   * Picture 1 taken base64 data
   */
  image_file_1?: string;
  /**
   * Picture 2 taken base64 data
   */
  image_file_2?: string;
  /**
   * Picture OVV taken base64 data
   */
  image_file_ovv?: string;

  valid?: boolean;
}

