/**
 * opsinspectionss REST API
 * opsinspectionss API from XPO
 *
 * The version of the OpenAPI document: 1.0.0-1-1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Foo Dto.
 */
export interface DummyDto { 
    id?: string;
    /**
     * Must be a valid pro number.
     */
    proNbr?: string;
    comment?: string;
}

