import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../lib/helpers/date-helper';

@Pipe({
  name: 'portlandDate',
})
export class PortlandDatePipe extends DatePipe implements PipeTransform {
  override transform(value: any): any {
    const result = DateHelper.getPortandTzFormattedDate(value, DateHelper.ANGULAR_PIPE);
    return result;
  }
}
