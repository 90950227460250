/**
 * opsinspectionss REST API
 * opsinspectionss API from XPO
 *
 * The version of the OpenAPI document: 1.0.0-1-1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Extended Document.
 */
export interface ExtendedDocumentDto { 
    base64data?: string;
    timestampPortland?: Date;
    timestampIso?: string;
    corpCode?: string;
    timestamp?: string;
    relevanceValue?: number;
}

