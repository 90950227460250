/**
 * OpsDimensioners REST API
 * OpsDimensioners API from XPO
 *
 * The version of the OpenAPI document: 1.0.0-1-3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateInsDimensionDocumentModel { 
    /**
     * Base64 data
     */
    base64Data?: string;
    /**
     * Mime Type
     */
    mimeType?: string;
    /**
     * Document identifier.
     */
    identifier?: string;
    validForDmsStorage?: boolean;
}

