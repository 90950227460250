import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../lib/helpers/date-helper';

@Pipe({
  name: 'portlandDateOrNa',
})
export class PortlandDateOrNa extends DatePipe implements PipeTransform {
  override transform(value: any): any {
    const result =
      DateHelper.getPortandTzFormattedDate(value, DateHelper.ANGULAR_PIPE) ||
      DateHelper.DEFAULT_NULL_VALUE;
    return result;
  }
}
