/**
 * OpsDimensioners REST API
 * OpsDimensioners API from XPO
 *
 * The version of the OpenAPI document: 1.0.0-1-3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Pagination links for the pagination envelope.
 */
export interface PaginationEnvelopeLinks { 
    /**
     * The URL to request the first page.
     */
    first?: string;
    /**
     * The URL to request the previous page, if any.
     */
    previous?: string;
    /**
     * The URL to request the next page, if any.
     */
    next?: string;
    /**
     * The URL to request the last page.
     */
    last?: string;
}

