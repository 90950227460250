/**
 * OpsDimensioners REST API
 * OpsDimensioners API from XPO
 *
 * The version of the OpenAPI document: 1.0.0-1-3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Pagination details for the pagination envelope.
 */
export interface PaginationDetails { 
    /**
     * The pagination offset, which represents how many records will be skipped.
     */
    offset?: number;
    /**
     * The limit for the amount of records to be included in the paginated results.
     */
    limit?: number;
    /**
     * The total records available.
     */
    total?: number;
}

